// @flow
import React from 'react'

const Error = () => (
  <div>
    <div className="py-5 text-center">
      <img
        className="d-block mx-auto mb-4"
        src="https://www.sofokus.com/wp-content/uploads/2018/07/mono-black-rgb.png"
        alt=""
        width="300"
      />
      <h2>404</h2>
      <p className="lead">The page you were looking for doesn&apos;t exist.</p>
    </div>
  </div>
)

export default Error
