import { Get, Post, Delete } from 'api/index'

export const LIST_USERS = 'LIST_USERS'
export const CREATE_USER = 'CREATE_USER'
export const GET_USER = 'GET_USER'
export const DELETE_USER = 'DELETE_USER'

export const usersList = () => (dispatch) => {
  const url = `/api/users/`
  return dispatch({
    type: LIST_USERS,
    payload: Get(url)
  })
}

export const usersCreate = (body) => (dispatch) => {
  const url = `/api/users/`
  return dispatch({
    type: CREATE_USER,
    payload: Post(url, JSON.stringify(body))
  })
}

export const usersGet = (id) => (dispatch) => {
  const url = `/api/users/${id}/`
  return dispatch({
    type: GET_USER,
    payload: Get(url)
  })
}

export const usersDelete = (id) => (dispatch) => {
  const url = `/api/users/${id}/`
  return dispatch({
    type: GET_USER,
    payload: Delete(url)
  })
}
