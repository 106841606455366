// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import axios from 'axios'
import uuid from 'react-uuid'
import { Progress } from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetErrorMessage } from 'store/actions/error'
import { createGet } from 'store/actions/create'
import Error from 'components/Error'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Form from 'react-bootstrap/Form'

const apiBaseUrl = `${process.env.REACT_APP_API_URL}/`

class CreateSecret extends Component<
  { createGet: (Object) => void, create: Object, history: Object },
  {}
> {
  constructor(props) {
    super(props)
    this.state = {
      selectedFile: null,
      uploadedFiles: [],
      uploadErrors: [],
      uploadId: uuid(),
      loaded: null,
      allowedCountries: ['FI'],
      data: '',
      textChars: '0 characters',
      createErrors: null
    }
  }

  componentDidMount() {
    const { createGet: createGetAction } = this.props
    createGetAction()
  }

  getUpdateSecretText = () => {
    const { uploadedFiles, data } = this.state
    const { create } = this.props
    let text = `Content length ${data.length} characters `
    if (create.fileUploadEnabled && uploadedFiles.length > 0)
      text += `/ ${uploadedFiles.length} files`
    return text
  }

  onChange = (event) => {
    this.setState({
      textChars: this.getUpdateSecretText(),
      [event.target.name]: event.target.value
    })
  }

  onSelectChange = (event) => {
    const allowedCountries = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    )
    this.setState({
      allowedCountries
    })
  }

  onSubmit = async (e) => {
    e.preventDefault()
    const data = this.state
    const { create, history } = this.props
    await fetch(`${apiBaseUrl}api/create/secret`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'xsrf-token': create.csrfToken
      },
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then(() => {
        this.setState({
          createErrors: null
        })
        history.push('/s')
      })
      .catch((error) => {
        const createErrors = JSON.stringify(error)
        this.setState({
          createErrors
        })
      })
    return false
  }

  deleteUploadedFile = () => {}

  onClickHandler = () => {
    const data = new FormData()
    const { selectedFile, uploadedFiles, uploadErrorsState, uploadId } =
      this.state
    data.append('file', selectedFile)
    data.append('uploadId', uploadId)
    axios
      .post(`${apiBaseUrl}api/upload/file`, data, {
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100
          })
        }
      })
      .then((res) => {
        if (res.data.status) {
          const files = uploadedFiles
          files.push(res.data.filename)
          this.setState({
            textChars: this.getUpdateSecretText(),
            uploadErrors: [],
            uploadedFiles: files,
            loaded: null
          })
        } else {
          const uploadErrors = uploadErrorsState
          uploadErrors.push(`Uploading file ${selectedFile} failed. `)
          this.setState({
            uploadedFiles: uploadErrors
          })
        }
      })
      .catch((error) => {
        const uploadErrors = uploadErrorsState
        uploadErrors.push(`Uploading file ${selectedFile} failed. ${error}`)
        this.setState({
          uploadErrors
        })
      })
  }

  onChangeHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0
    })
  }

  render() {
    const {
      allowedCountries,
      loaded,
      uploadedFiles,
      uploadErrors,
      textChars,
      email,
      createErrors
    } = this.state
    const { create } = this.props
    if ((create && !create.isAllowed) || !create) return <Error />
    let progress = null
    if (loaded > 0) {
      progress = (
        <Progress max="100" color="success" value={loaded}>
          {Math.round(loaded, 2)}%
        </Progress>
      )
    }
    const errors = []
    if (uploadErrors.length > 0) {
      Object.entries(uploadErrors).forEach((value) => {
        errors.push(
          <li className="list-group-item list-group-item-danger">
            <strong>Error: </strong> {value}
          </li>
        )
      })
    }
    const files = []
    if (uploadedFiles.length > 0) {
      Object.entries(uploadErrors).forEach((value) => {
        files.push(
          <li className="list-group-item">
            <strong>Filename: </strong> {value}{' '}
            <button
              type="button"
              className="btn btn-sm btn-danger float-right mt-0 mb-0 pt-0 pb-0"
            >
              Delete
            </button>
          </li>
        )
      })
    }

    const countriesSelect = []
    /* eslint-disable */
    const selectedCountry = 'FI'
    let allowedCountriesLabels = []
    for (var key in create.countries) {
      const c = create.countries[key]
      let countriesArray = []
      c.forEach((country) => {
        const code = country.value
        const label = country.label
        if (allowedCountries.includes(code)) {
          allowedCountriesLabels.push(label)
        }
        if (code === selectedCountry)
          countriesArray.push(
            <option key={code} selected value={code}>
              {label}
            </option>
          )
        else
          countriesArray.push(
            <option key={code} value={code}>
              {label}
            </option>
          )
      })
      countriesSelect.push(
        <optgroup key={key} label={key}>
          {' '}
          {countriesArray}{' '}
        </optgroup>
      )
    }
    /* eslint-enable */

    return (
      <div>
        <div className="py-5 text-center">
          <img
            className="d-block mx-auto mb-4"
            src="https://www.sofokus.com/wp-content/uploads/2018/07/mono-black-rgb.png"
            alt=""
            width="300"
          />
          <h2>Create secret</h2>
          <p className="lead">
            Create secret and share it with 3rd party via email.
          </p>
        </div>
        <div className="container">
          {createErrors && (
            <div className="alert alert-danger" role="alert">
              {createErrors}
            </div>
          )}
          <div className="row mb-3">
            <div className="col-md-4 order-md-2 mb-4">
              <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Overview</span>
              </h4>
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="my-0">Secret</h6>
                    <small className="text-muted">{textChars}</small>
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="my-0">Email</h6>
                    <small className="text-muted">{email}</small>
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="my-0">Allowed countries</h6>
                    <small className="text-muted">
                      {allowedCountriesLabels.join(', ')}
                    </small>
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Expiration date</span>
                  <strong>{create.expirationDate}</strong>
                </li>
              </ul>
            </div>
            <div className="col-md-8 order-md-1">
              <h4 className="mb-3">Secret details</h4>
              <form
                className="needs-validation"
                noValidate=""
                onSubmit={this.onSubmit}
              >
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <Tabs
                      defaultActiveKey="secret-content"
                      id="uncontrolled-tab-example"
                    >
                      <Tab eventKey="secret-content" title="Secret content">
                        <br />
                        <textarea
                          className="form-control"
                          name="data"
                          placeholder="Secret content"
                          required
                          onChange={this.onChange}
                        />
                        <div className="invalid-feedback">
                          Secret content is required.
                        </div>
                      </Tab>
                      {create.fileUploadEnabled && (
                        <Tab eventKey="files" title="Files">
                          <br />
                          <input
                            name="uploadId"
                            type="hidden"
                            value="{this.state.uploadId}"
                            onChange={this.onChange}
                          />
                          <div
                            className="btn-toolbar mb-3"
                            role="toolbar"
                            aria-label="Download"
                          >
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="Download"
                            >
                              <div className="custom-file">
                                <input
                                  type="file"
                                  name="filename"
                                  className="custom-file-input"
                                  id="customFile"
                                  onChange={this.onChangeHandler}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="customFile"
                                >
                                  Choose file
                                </label>
                              </div>
                              <button
                                type="button"
                                onClick={this.onClickHandler}
                                className="btn btn-primary"
                              >
                                Upload
                              </button>
                            </div>
                          </div>

                          {progress}

                          <ul className="list-group errors mt-3">{errors}</ul>
                          <ul className="list-group uploaded-files mt-3">
                            {files}
                          </ul>
                          <input
                            name="uploadId"
                            type="hidden"
                            value="{this.state.uploadId}"
                            onChange={this.onChange}
                          />
                          <div
                            className="btn-toolbar mb-3"
                            role="toolbar"
                            aria-label="Download"
                          >
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="Download"
                            >
                              <div className="custom-file">
                                <input
                                  type="file"
                                  name="filename"
                                  className="custom-file-input"
                                  id="customFile"
                                  onChange={this.onChangeHandler}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="customFile"
                                >
                                  Choose file
                                </label>
                              </div>
                              <button
                                type="button"
                                onClick={this.onClickHandler}
                                className="btn btn-primary"
                              >
                                Upload
                              </button>
                            </div>
                          </div>

                          {progress}

                          <ul className="list-group errors mt-3">{errors}</ul>
                          <ul className="list-group uploaded-files mt-3">
                            {files}
                          </ul>
                        </Tab>
                      )}
                    </Tabs>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="email">Email</label>
                  <Form.Control
                    onChange={this.onChange}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="firstname.lastname@example.com"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="emailMessage">
                      Email message for secret recipient
                    </label>
                    <textarea
                      className="form-control"
                      name="emailMessage"
                      placeholder="Email message"
                      required
                      onChange={this.onChange}
                    />
                    <div className="invalid-feedback">
                      Email message is required.
                    </div>
                  </div>
                </div>
                <h4 className="mb-3">Security settings</h4>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <Tabs
                      defaultActiveKey="geo-ip-restriction"
                      id="security-settings-tabs"
                    >
                      <Tab
                        eventKey="geo-ip-restriction"
                        title="Geo IP restriction"
                      >
                        <br />
                        <select
                          className="custom-select d-block w-100"
                          defaultValue={[
                            { label: selectedCountry, value: selectedCountry }
                          ]}
                          name="allowedCountries"
                          id="country"
                          multiple
                          required=""
                          data-cip-id="cIPJQ342845640"
                          onChange={this.onSelectChange}
                        >
                          <option value="">Choose...</option>
                          {countriesSelect}
                        </select>
                        <small className="form-text text-muted">
                          Select allowed countries for viewing secret. Country
                          is determined based on user IP address.
                        </small>
                        <div className="invalid-feedback">
                          Please select a valid country.
                        </div>
                      </Tab>
                      <Tab
                        eventKey="pre-shared-key-restriction"
                        title="Password"
                      >
                        <br />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          onChange={this.onChange}
                        />
                        <small className="form-text text-muted">
                          Password must be entered before secret can be viewed.
                        </small>
                      </Tab>
                      <Tab
                        eventKey="subnet-ip-restriction"
                        title="Subnet/IP-restriction"
                      >
                        <br />
                        <input
                          type="text"
                          className="form-control"
                          name="subnetIpRestriction"
                          placeholder="Subnet/IP-restriction"
                          onChange={this.onChange}
                        />
                        <small className="form-text text-muted">
                          Values are comma separated. Example:
                          2a05:d07c:2000:0:0:0:0:0/120,192.168.1.0/24
                        </small>
                      </Tab>
                    </Tabs>
                  </div>
                  <hr className="mb-4" />
                  <button
                    className="btn btn-primary btn-lg btn-block"
                    type="submit"
                  >
                    Send secret
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  create: state.create.create
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createGet,
      resetErrorMessage
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateSecret)
)
