import { Get } from 'api/index'

export const GET_CREATE = 'GET_CREATE'

export const createGet = () => (dispatch) => {
  const url = `/api/create/secret/`
  return dispatch({
    type: GET_CREATE,
    payload: Get(url)
  })
}
