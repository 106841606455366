import { Get } from 'api/index'

export const GET_SECRET = 'GET_SECRET'

export const secretGet = (id, password) => (dispatch) => {
  const url = `/api/view/secret/${id}?password=${password}`
  return dispatch({
    type: GET_SECRET,
    payload: Get(url)
  })
}
