// @flow
import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import type { Node } from 'react'

export default ({ children }: { children: Node }) => (
  <div>
    {children}
    <ToastContainer position="top-center" />
  </div>
)
