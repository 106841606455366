// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetErrorMessage } from 'store/actions/error'
import { secretGet } from 'store/actions/secret'
import Error from 'components/Error'

class ViewSecret extends Component<
  { secretGet: (Object) => void, secret: Object, match: any },
  {}
> {
  constructor(props) {
    super(props)
    this.state = {
      password: null
    }
  }

  componentDidMount() {
    const { match, secretGet: secretGetAction } = this.props
    const { password } = this.state
    secretGetAction(match.params.secretId, password || '')
  }

  copyToClipboard = () => {
    const { secret } = this.props
    const textField = document.createElement('textarea')
    textField.innerText = secret.data
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  openSecret = (event) => {
    this.setState({
      password: event.target.value
    })
    this.componentDidMount()
  }

  render() {
    const { secret } = this.props
    if (secret && secret.passwordRequired) {
      return (
        <div>
          <div className="py-5 text-center">
            <img
              className="d-block mx-auto mb-4"
              src="https://www.sofokus.com/wp-content/uploads/2018/07/mono-black-rgb.png"
              alt=""
              width="300"
            />
            <h2>Enter password to display secret</h2>
          </div>
          <div className="container">
            <div className="row mb-3">
              <div className="col-sm-3" />
              <div className="col-sm-6">
                <div className="input-group mb-3">
                  <input
                    name="password"
                    type="text"
                    className="form-control"
                    placeholder="Key"
                    aria-label="Key"
                    aria-describedby="button-addon2"
                    onKeyUp={this.openSecret}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="button"
                      id="button-addon2"
                      onClick={this.openSecret}
                    >
                      Open
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </div>
      )
    }
    if ((secret && !secret.result) || !secret) return <Error />
    const items = []
    let files = null
    if (secret.files) {
      secret.files.forEach((value) => {
        items.push(
          <li className="list-group-item">
            <a
              type="button"
              className="btn btn-primary"
              title="Download {value.filename}"
              href="{value.filename}"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-cloud-download"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
                />
                <path
                  fillRule="evenodd"
                  d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"
                />
              </svg>

              <span className="pl-1">{value.filename}</span>
            </a>

            <strong className="pl-1">{value.size}</strong>
            <span className="badge badge-light">
              {value.downloads_left} downloads left
            </span>
          </li>
        )
      })
      files = (
        <div className="col-md-12">
          <h4 className="mb-3">Files</h4>
          <div className="row">
            <div className="col-md-12 mb-3">
              <ul className="list-group">{items}</ul>
            </div>
          </div>
        </div>
      )
    }

    let data = null
    if (secret.data) {
      data = (
        <div className="col-md-12">
          <h4 className="mb-3">
            Secret
            <button
              type="button"
              onClick={this.copyToClipboard}
              className="btn btn-primary float-right"
            >
              Copy secret content to clipboard
            </button>
          </h4>
          <div className="row">
            <div className="col-md-12 mb-3">
              <textarea
                id="data"
                className="form-control mb-3"
                placeholder="Secret content"
                readOnly="readonly"
              >
                {secret.data}
              </textarea>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className="py-5 text-center">
          <img
            className="d-block mx-auto mb-4"
            src="https://www.sofokus.com/wp-content/uploads/2018/07/mono-black-rgb.png"
            alt=""
            width="300"
          />
          <h2>Sofokus shared secret for you</h2>
          <p className="lead">
            You can view this secret until{' '}
            <strong>{secret.expiration_date}</strong> and view secret{' '}
            <strong>{secret.views_left}</strong> times before this data is
            automatically deleted.
          </p>
        </div>
        <div className="container">
          <div className="row mb-3">
            {data}
            {files}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  secret: state.secret.secret
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      secretGet,
      resetErrorMessage
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ViewSecret)
