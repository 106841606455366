import {
  LOGIN,
  LOGOUT,
  USER_READ,
  PASSWORD_RESET,
  PASSWORD_CONFIRM,
  PASSWORD_CONFIRM_RESET
} from 'store/actions/auth'

import produce from 'immer'

const API_TOKEN = sessionStorage.getItem('API_TOKEN')

const initialState = {
  token: API_TOKEN,
  isPending: false,
  error: [],
  tokenExpired: false,
  loggedIn: !!API_TOKEN,
  user: [],
  passwordResetRedirect: false,
  passwordResetMessage: null,
  passwordResetSend: false
}

const auth = (state = initialState, action) => {
  const { type, error, payload } = action

  if (error && payload.messages.code === 'token_expired') {
    return produce(state, (draft) => {
      draft.tokenExpired = true
      draft.isPending = false
      draft.error = payload.response
      draft.loggedIn = false
    })
  }

  switch (type) {
    case `${LOGIN}_PENDING`:
      return produce(state, (draft) => {
        draft.isPending = true
        draft.error = []
      })

    case `${LOGIN}_REJECTED`:
      return produce(state, (draft) => {
        draft.isPending = false
        draft.error = payload
        draft.loggedIn = false
      })

    case `${LOGIN}_FULFILLED`:
      sessionStorage.setItem('API_TOKEN', payload.key)
      return produce(state, (draft) => {
        draft.token = payload.key
        draft.tokenExpired = false
        draft.isPending = false
        draft.loggedIn = true
      })

    case `${USER_READ}_PENDING`:
      return produce(state, (draft) => {
        draft.isPending = true
        draft.error = []
        draft.user = []
      })

    case `${USER_READ}_REJECTED`:
      return produce(state, (draft) => {
        draft.isPending = false
        draft.error = payload
        draft.user = []
      })

    case `${USER_READ}_FULFILLED`:
      return produce(state, (draft) => {
        draft.user = payload
        draft.isPending = false
      })
    case `${LOGOUT}_PENDING`:
      return produce(state, (draft) => {
        draft.isPending = true
      })

    case `${LOGOUT}_FULFILLED`:
    case `${LOGOUT}_REJECTED`:
      sessionStorage.removeItem('API_TOKEN')
      return produce(state, (draft) => {
        draft.isPending = false
        draft.error = payload
        draft.user = []
        draft.loggedIn = false
        draft.token = null
      })

    case `${PASSWORD_RESET}_PENDING`:
      return produce(state, (draft) => {
        draft.passwordResetMessage = null
        draft.passwordResetError = null
        draft.passwordResetSend = false
        draft.error = []
        draft.isPending = true
      })

    case `${PASSWORD_RESET}_REJECTED`:
      return produce(state, (draft) => {
        draft.passwordResetError = payload
        draft.error = payload
        draft.isPending = false
        draft.passwordResetSend = false
      })

    case `${PASSWORD_RESET}_FULFILLED`:
      return produce(state, (draft) => {
        draft.passwordResetMessage = payload.detail
        draft.isPending = false
        draft.passwordResetSend = true
      })

    case `${PASSWORD_CONFIRM}_PENDING`:
      return produce(state, (draft) => {
        draft.passwordResetRedirect = false
        draft.passwordResetMessage = null
        draft.error = []
        draft.isPending = true
      })

    case `${PASSWORD_CONFIRM}_REJECTED`:
      return produce(state, (draft) => {
        draft.error = payload
        draft.isPending = false
      })

    case `${PASSWORD_CONFIRM}_FULFILLED`:
      return produce(state, (draft) => {
        draft.passwordResetRedirect = true
        draft.passwordResetMessage = payload.detail
        draft.isPending = false
      })

    case PASSWORD_CONFIRM_RESET:
      return produce(state, (draft) => {
        draft.passwordResetRedirect = false
        draft.passwordResetMessage = null
        draft.error = []
      })
    default:
      return state
  }
}

export default auth

/* const typeReducer = typeToReducer(
  {
    [LOGIN]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { response } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = response
        }),
      FULFILLED: (state, action) => {
        const {
          payload: { key }
        } = action
        sessionStorage.setItem('API_TOKEN', key)
        return produce(state, draft => {
          draft.token = key
          draft.isPending = false
        })
      }
    }
  },
  initialState
) */
/* 
const foo = (storestate = initialState, action) => {
  const { type, error, payload } = action

  const typeReducer = typeToReducer(
    {
      [LOGIN]: {
        PENDING: state =>
          produce(state, draft => {
            draft.isPending = true
          }),

        REJECTED: (state, { payload: { response } }) =>
          produce(state, draft => {
            draft.isPending = false
            draft.error = response
          }),
        FULFILLED: (state, action) => {
          const {
            payload: { key }
          } = action
          sessionStorage.setItem('API_TOKEN', key)
          return produce(state, draft => {
            draft.token = key
            draft.isPending = false
            draft.tokenExpired = false
          })
        }
      }
    },
    initialState
  )

  console.log(typeReducer)
  return typeReducer
}

export default foo */
