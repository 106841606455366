import { GET_CREATE } from 'store/actions/create'
import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  create: [],
  isPending: false,
  error: {
    messages: []
  }
}

export default typeToReducer(
  {
    [GET_CREATE]: {
      PENDING: (state) =>
        produce(state, (draft) => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { response } }) =>
        produce(state, (draft) => {
          draft.isPending = false
          draft.error = response
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, (draft) => {
          draft.create = payload
          draft.isPending = false
        })
    }
  },
  initialState
)
