// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'

class SuccessPage extends Component<{}, {}> {
  render() {
    return (
      <div>
        <div className="py-5 text-center">
          <img
            className="d-block mx-auto mb-4"
            src="https://www.sofokus.com/wp-content/uploads/2018/07/mono-black-rgb.png"
            alt=""
            width="300"
          />
          <h2>Secret created</h2>
          <p className="lead">Secret will be emailed to recipient soon!</p>
          <a className="btn btn-primary" href="/" role="button">
            Create a new secret
          </a>
        </div>
      </div>
    )
  }
}

export default connect()(SuccessPage)
