// @flow

import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { initStore } from 'store/store'

import { ViewSecret, CreateSecret, SuccessPage } from 'pages'

import Layout from 'components/layouts/Layout'
import 'normalize.css'
import 'App.scss'
import 'typeface-roboto'
import 'assets/css/bootstrap/bootstrap.min.css'

export default () => (
  <Provider store={initStore()}>
    <Router>
      <Layout>
        <Switch>
          <Route component={ViewSecret} path="/v/:secretId" />
          <Route component={CreateSecret} exact path="/" />
          <Route component={SuccessPage} exact path="/s" />
        </Switch>
      </Layout>
    </Router>
  </Provider>
)
