import { combineReducers } from 'redux'

import { RESET_ERROR_MESSAGE } from 'store/actions/error'
import auth from './auth'
import users from './users'
import secret from './secret'
import create from './create'

const errorMessage = (state = null, action) => {
  const { type, error, payload } = action

  if (type === RESET_ERROR_MESSAGE) {
    return null
  } else if (error && payload.generalError) {
    const { status, statusText, messages } = payload
    return { status, statusText, messages }
  }

  return state
}

export default combineReducers({
  auth,
  error: errorMessage,
  users,
  secret,
  create
})
