import { encodeQueryParams } from 'util/url'
import ApiError from 'ApiError'

import 'unfetch/polyfill'

const ApiCall = (path, method, body) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
  const pathWithApiUrl = `${process.env.REACT_APP_API_URL}${path}`

  // For authentication
  const token = sessionStorage.getItem('API_TOKEN')
  headers.Authorization = token ? `Token ${token}` : null

  return fetch(pathWithApiUrl, {
    headers,
    method,
    credentials: 'include',
    mode: 'cors',
    body: body || undefined
  }).then((response) =>
    response[response.status === 204 ? 'text' : 'json']()
      .then((json) => {
        if (!response.ok) {
          const Error = new ApiError(response, json)

          return Promise.reject(Error)
        }
        return json
      })
      .catch((error) => Promise.reject(error))
  )
}

export function Get(path, params = {}) {
  return ApiCall(path + encodeQueryParams(params), 'GET', null)
}

export function Post(path, body) {
  return ApiCall(path, 'POST', body)
}

export function Put(path, body) {
  return ApiCall(path, 'PUT', body)
}

export function Patch(path, body) {
  return ApiCall(path, 'PATCH', body)
}

export function Delete(path) {
  return ApiCall(path, 'DELETE', null)
}
