import { Get, Post } from 'api/index'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const USER_READ = 'USER_READ'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_CONFIRM = 'PASSWORD_CONFIRM'
export const PASSWORD_CONFIRM_RESET = 'PASSWORD_CONFIRM_RESET'

export const login = (body) => (dispatch) => {
  const url = `/api/auth/login/`
  return dispatch({
    type: LOGIN,
    payload: Post(url, JSON.stringify(body))
  })
}

export const logout = () => (dispatch) => {
  const url = `/api/auth/logout/`
  return dispatch({
    type: LOGOUT,
    payload: Get(url)
  })
}

export const userRead = () => (dispatch) => {
  const url = `/api/auth/user/`
  return dispatch({
    type: USER_READ,
    payload: Get(url)
  })
}
/* eslint-disable */
export const resetPassword = (body: { email: string }) => (dispatch) => {
  /* eslint-enable */
  const url = `/api/auth/password/reset/`
  return dispatch({
    type: PASSWORD_RESET,
    payload: Post(url, JSON.stringify(body))
  })
}

export const resetPasswordConfirm =
  /* eslint-disable */

    (body: {
      new_password1: string,
      new_password2: string,
      uid: string,
      token: string
    }) =>
    (dispatch) => /* eslint-enable */
    {
      const url = `/api/auth/password/reset/confirm/`
      return dispatch({
        type: PASSWORD_CONFIRM,
        payload: Post(url, JSON.stringify(body))
      })
    }

export const resetPasswordConfirmReset = () => (dispatch) =>
  dispatch({
    type: PASSWORD_CONFIRM_RESET
  })
